<template>
  <NotificationBase
    :notification
    :icon
    :compact
    link="/personal/transactions?tab=deposit"
  >
    <template #subject>
      {{ t('notifications.subject.partClime') }}
    </template>
    <i18n-t
      v-if="notification.data.sourceType === 'freebet'"
      keypath="notifications.body.partClimeFreebet"
      tag="p"
    >
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
    <i18n-t
      v-else-if="notification.data.sourceType === 'userFreespinProgram'"
      keypath="notifications.body.partClimeFreespin"
      tag="p"
    >
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
    <i18n-t v-else keypath="notifications.body.partClimeBonus" tag="p">
      <template #amount>
        <b>{{ notificationAmount }}</b>
      </template>
    </i18n-t>
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { PartClaimeNotification } from '../../../../types'

const { notification, compact = false } = defineProps<{
  notification: PartClaimeNotification
  compact?: boolean
}>()

const { t } = useI18n()

const { notificationAmount, currencyIcon } = useCurrencyNotification(
  toRef(() => notification),
)

const bonusIcon = computed(() => {
  switch (notification.data.sourceType) {
    case 'freebet':
      return 'freebet' as const
    case 'userFreespinProgram':
      return 'freespins' as const
    default:
      return 'gift-solid' as const
  }
})

const icon = computed(() => ({
  name: bonusIcon.value,
  currencyIcon: currencyIcon.value,
}))
</script>
